import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AdvancedAnalyticsDataDTO } from '@pages/portfolios/portfolio/advanced-analytics/advanced-analytics.types';
import { InstrumentTinyDTO } from '@shared/dto/gateway-secured/instrument-tiny-dto';
import { HistoryWidgetDTO } from '@shared/dto/positions/history-widget-dto';
import {
  FixedIncomeWidgetDTO,
  MarketCorrelationDTO,
  PortfolioChartPerformanceDTO,
  PortfolioSummaryDTO,
  PositionConciseDTO,
  SearchRequestDTO,
  StrategyChartPerformanceExtendedDTO,
  Top5PortfolioChartPerformanceWrapperDTO,
  WidgetDataDTO,
  WidgetDataPnlPeriodsDTO,
} from '@shared/dto/positions/models';
import { SimpleDateValueDTO } from '@shared/dto/positions/simple-date-value-dto';
import { SimpleInstrumentDTO } from '@shared/dto/positions/simple-instrument-dto';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { SettingsState } from '@shared/states/settings.state';
import { UiDashboardPositionsData } from '@shared/types/dashboard-positions-data';
import { DataWithError } from '@shared/types/data-with-error';
import { SourceEntityType } from '@shared/types/source-entity-type';
import { WidgetDateValue } from '@shared/types/widget-data-pnl-period';
import { LazyData } from '@ui/table/table-types';

@Injectable({
  providedIn: 'root',
})
export class WidgetsService {
  constructor(
    private settingsState: SettingsState,
    private http: HttpClient,
    private dateHelper: DateHelper,
  ) {}

  public getOpenDate(accountId?: string): Observable<string> {
    const params: Record<string, string> = {};

    if (accountId) {
      params.accountId = accountId;
    }

    return this.http
      .get<string>(`${this.settingsState.apiPath}/widgets/open-date`, {
        params,
      })
      .pipe(catchError(() => of(null)));
  }

  public getDashboardSummary(requestParams: {
    range?: {
      start: Date;
      end: Date;
    };
    ccy?: string;
    bankAccountIds?: string[];
  }): Observable<PortfolioSummaryDTO> {
    const params: Record<string, string> = {};

    if (
      requestParams.range.start &&
      !this.dateHelper.isEqual(requestParams.range.start, this.dateHelper.epoch())
    ) {
      params.startDate = this.dateHelper.format(requestParams.range.start);
    }

    if (requestParams.range) {
      params.date = this.dateHelper.format(requestParams.range.end);
    }

    if (requestParams.ccy) {
      params.ccy = requestParams.ccy;
    }

    if (requestParams.bankAccountIds?.length) {
      params.bankAccountIds = requestParams.bankAccountIds.join(',');
    }

    return this.http
      .get<PortfolioSummaryDTO>(`${this.settingsState.apiPath}/widgets/dashboard-summary`, {
        params,
      })
      .pipe(catchError(() => of(null)));
  }

  public getDashboardSummaryOfEntity(
    entityId: string,
    entityType: SourceEntityType,
    requestParams: {
      range?: {
        start: Date;
        end: Date;
      };
      ccy?: string;
      portfolioCcy?: string;
      portfolioOpenDate?: Date;
      bankAccountIds?: string[];
    },
  ): Observable<PortfolioSummaryDTO> {
    let url: string;

    switch (entityType) {
      case 'portfolio':
      case 'consolidated-portfolio':
        url = `${this.settingsState.apiPath}/widgets/${entityId}/summary`;
        break;

      case 'account':
        url = `${this.settingsState.apiPath}/widgets/accounts/${entityId}/summary`;
        break;
    }

    const params: Record<string, string | string[]> = {};

    if (requestParams.ccy && requestParams.ccy !== requestParams.portfolioCcy) {
      params.ccy = requestParams.ccy;
    }

    if (requestParams.bankAccountIds?.length) {
      params.bankAccountIds = requestParams.bankAccountIds.join(',');
    }

    if (
      requestParams.range.start &&
      !this.dateHelper.isEqual(requestParams.range.start, requestParams.portfolioOpenDate) &&
      !this.dateHelper.isEqual(requestParams.range.start, this.dateHelper.epoch())
    ) {
      params.startDate = this.dateHelper.format(requestParams.range.start);
    }

    if (requestParams.range.end) {
      params.date = this.dateHelper.format(requestParams.range.end);
    }

    return this.http.get<PortfolioSummaryDTO>(url, { params }).pipe(catchError(() => of(null)));
  }

  public getDashboardPerformance(
    range: { start: Date; end: Date },
    openDate: Date,
    ccy?: string,
  ): Observable<PortfolioChartPerformanceDTO[]> {
    const params: Record<string, string> = {
      endDate: this.dateHelper.format(range.end),
    };

    if (
      range.start &&
      !this.dateHelper.isEqual(range.start, openDate) &&
      !this.dateHelper.isEqual(range.start, this.dateHelper.epoch())
    ) {
      params.startDate = this.dateHelper.format(range.start);
    }

    if (ccy) {
      params.ccy = ccy;
    }

    return this.http
      .get<PortfolioChartPerformanceDTO[]>(
        `${this.settingsState.apiPath}/widgets/dashboard-performance/custom-period`,
        {
          params,
        },
      )
      .pipe(
        map((performance) => {
          if (performance?.length) {
            performance.map((item) => {
              const date = this.dateHelper.parse(item.date);
              date.setHours(0);
              date.setMinutes(0);

              return { ...item, date };
            });
          }

          return performance;
        }),
      );
  }

  public getDashboardPositionsDataForWidgets(
    range: { start: Date; end: Date },
    openDate: Date,
    ccy?: string,
  ): Observable<UiDashboardPositionsData> {
    const params: Record<string, string> = {
      date: this.dateHelper.format(range.end),
    };

    if (
      range.start &&
      !this.dateHelper.isEqual(range.start, openDate) &&
      !this.dateHelper.isEqual(range.start, this.dateHelper.epoch())
    ) {
      params.startDate = this.dateHelper.format(range.start);
    }

    if (ccy) {
      params.ccy = ccy;
    }

    return this.http
      .get<UiDashboardPositionsData>(
        `${this.settingsState.apiPath}/widgets/dashboard-positions-for-widgets`,
        {
          params,
        },
      )
      .pipe(catchError(() => of(null)));
  }

  public getPositionsDataForWidgets(
    entityId: string,
    entityType: SourceEntityType,
    requestParams: {
      range: { start: Date; end: Date };
      ccy?: string;
      portfolioCcy?: string;
      portfolioOpenDate?: Date;
      bankAccountIds?: string[];
      productId?: string;
    },
  ): Observable<UiDashboardPositionsData> {
    let url: string;

    switch (entityType) {
      case 'portfolio':
      case 'consolidated-portfolio':
        url = `${this.settingsState.apiPath}/widgets/${entityId}/positions-for-widgets`;
        break;

      case 'account':
        url = `${this.settingsState.apiPath}/widgets/accounts/${entityId}/positions-for-widgets`;
        break;
    }

    const params: Record<string, string> = {};

    if (
      requestParams.range.start &&
      !this.dateHelper.isEqual(requestParams.range.start, requestParams.portfolioOpenDate) &&
      !this.dateHelper.isEqual(requestParams.range.start, this.dateHelper.epoch())
    ) {
      params.startDate = this.dateHelper.format(requestParams.range.start);
    }

    if (requestParams.range.end) {
      params.date = this.dateHelper.format(requestParams.range.end);
    }

    if (requestParams.ccy && requestParams.ccy !== requestParams.portfolioCcy) {
      params.ccy = requestParams.ccy;
    }

    if (requestParams.bankAccountIds?.length) {
      params.bankAccountIds = requestParams.bankAccountIds.join(',');
    }

    if (requestParams.productId) {
      params.productId = requestParams.productId;
    }

    return this.http.get<UiDashboardPositionsData>(url, { params });
  }

  public getPerformance(
    entityId: string,
    entityType: SourceEntityType,
    requestParams: {
      range: { start: Date; end: Date };
      ccy?: string;
      portfolioCcy?: string;
      portfolioOpenDate?: Date;
      bankAccountIds?: string[];
    },
  ): Observable<PortfolioChartPerformanceDTO[]> {
    let url: string;

    switch (entityType) {
      case 'portfolio':
      case 'consolidated-portfolio':
        url = `${this.settingsState.apiPath}/widgets/${entityId}/performance/custom-period`;
        break;

      case 'account':
        url = `${this.settingsState.apiPath}/widgets/accounts/${entityId}/performance/custom-period`;
        break;
    }

    const params: Record<string, string> = {};

    if (
      requestParams.range.start &&
      !this.dateHelper.isEqual(requestParams.range.start, requestParams.portfolioOpenDate) &&
      !this.dateHelper.isEqual(requestParams.range.start, this.dateHelper.epoch())
    ) {
      params.startDate = this.dateHelper.format(requestParams.range.start);
    }

    if (requestParams.range.end) {
      params.endDate = this.dateHelper.format(requestParams.range.end);
    }

    if (requestParams.ccy && requestParams.ccy !== requestParams.portfolioCcy) {
      params.ccy = requestParams.ccy;
    }

    if (requestParams.bankAccountIds?.length) {
      params.bankAccountIds = requestParams.bankAccountIds.join(',');
    }

    return this.http.get<PortfolioChartPerformanceDTO[]>(url, { params }).pipe(
      map((performance) => {
        if (performance?.length) {
          performance.map((item) => {
            const date = this.dateHelper.parse(item.date);
            date.setHours(0);
            date.setMinutes(0);

            return { ...item, date };
          });
        }

        return performance;
      }),
    );
  }

  public getMarketList(): Observable<SimpleInstrumentDTO[]> {
    return this.http
      .get<SimpleInstrumentDTO[]>(
        `${this.settingsState.apiPath}/widgets/instruments/tr-market-list`,
      )
      .pipe(catchError(() => of(null)));
  }

  public getBenchmarkList(): Observable<DataWithError<InstrumentTinyDTO[]>> {
    return this.http
      .get<InstrumentTinyDTO[]>(`${this.settingsState.apiPath}/instruments/tr-market-default`)
      .pipe(
        map((data) => ({ data, error: null })),
        catchError((error) => of({ data: null, error })),
      );
  }

  public getDashboardTop5Positions(
    range: { start: Date; end: Date },
    ccy?: string,
    portfolioOpenDate?: Date,
  ): Observable<WidgetDataDTO[]> {
    const params: Record<string, string> = {
      date: this.dateHelper.format(range.end),
    };

    if (
      range?.start &&
      !this.dateHelper.isEqual(range.start, portfolioOpenDate) &&
      !this.dateHelper.isEqual(range.start, this.dateHelper.epoch())
    ) {
      params.startDate = this.dateHelper.format(range.start);
    }

    if (ccy) {
      params.ccy = ccy;
    }

    return this.http.get<WidgetDataDTO[]>(
      `${this.settingsState.apiPath}/widgets/dashboard-top-5-positions`,
      params,
    );
  }

  public getTop5Positions(
    entityId: string,
    entityType: SourceEntityType,
    requestParams: {
      range: {
        start: Date;
        end: Date;
      };
      ccy?: string;
      bankAccountIds?: string[];
      portfolioOpenDate?: Date;
    },
  ): Observable<WidgetDataDTO[]> {
    let url: string;

    switch (entityType) {
      case 'portfolio':
      case 'consolidated-portfolio':
        url = `${this.settingsState.apiPath}/widgets/${entityId}/top-5-positions`;
        break;

      case 'account':
        url = `${this.settingsState.apiPath}/widgets/accounts/${entityId}/top-5-positions`;
        break;
    }

    const params: Record<string, string | string[]> = {
      date: this.dateHelper.format(requestParams.range.end),
    };

    if (
      requestParams?.range?.start &&
      !this.dateHelper.isEqual(requestParams.range.start, requestParams.portfolioOpenDate) &&
      !this.dateHelper.isEqual(requestParams.range.start, this.dateHelper.epoch())
    ) {
      params.startDate = this.dateHelper.format(requestParams.range.start);
    }

    if (requestParams.ccy) {
      params.ccy = requestParams.ccy;
    }

    if (requestParams.bankAccountIds?.length) {
      params.bankAccountIds = requestParams.bankAccountIds.join(',');
    }

    return this.http.get<WidgetDataDTO[]>(url, { params });
  }

  public getTop5Portfolios(
    range: {
      start: Date;
      end: Date;
    },
    ccy?: string,
  ): Observable<Top5PortfolioChartPerformanceWrapperDTO> {
    const params: Record<string, string> = {
      startDate: this.dateHelper.format(range.start),
      endDate: this.dateHelper.format(range.end),
    };

    if (ccy) {
      params.ccy = ccy;
    }

    return this.http.get<Top5PortfolioChartPerformanceWrapperDTO>(
      `${this.settingsState.apiPath}/widgets/performance/top-5-portfolios/custom-period`,
      {
        params,
      },
    );
  }

  public getDashboardRegions(endDate?: string, ccy?: string): Observable<WidgetDataDTO[]> {
    const params: Record<string, string> = {};

    if (endDate) {
      params.date = endDate;
    }

    if (ccy) {
      params.ccy = ccy;
    }

    return this.http.get<WidgetDataDTO[]>(
      `${this.settingsState.apiPath}/widgets/dashboard-regions`,
      { params },
    );
  }

  public getRegions(
    entityId: string,
    entityType: SourceEntityType,
    requestParams: {
      range: {
        start: Date;
        end: Date;
      };
      ccy?: string;
      bankAccountIds?: string[];
    },
  ): Observable<WidgetDataDTO[]> {
    let url: string;

    switch (entityType) {
      case 'portfolio':
      case 'consolidated-portfolio':
        url = `${this.settingsState.apiPath}/widgets/${entityId}/regions`;
        break;

      case 'account':
        url = `${this.settingsState.apiPath}/widgets/accounts/${entityId}/regions`;
        break;
    }

    const params: Record<string, string | string[]> = {
      endDate: this.dateHelper.format(requestParams.range.end),
    };

    if (requestParams.range.start) {
      params.startDate = this.dateHelper.format(requestParams.range.start);
    }

    if (requestParams.ccy) {
      params.ccy = requestParams.ccy;
    }

    if (requestParams.bankAccountIds?.length) {
      params.bankAccountIds = requestParams.bankAccountIds.join(',');
    }

    return this.http.get<WidgetDataDTO[]>(url, { params });
  }

  public getDashboardCountriesOfRegion(regionId: string): Observable<WidgetDataDTO[]> {
    return this.http.get<WidgetDataDTO[]>(
      `${this.settingsState.apiPath}/widgets/dashboard-countries?regionName=${regionId}`,
    );
  }

  public getCountriesOfRegion(
    entityId: string,
    entityType: 'account' | 'portfolio',
    regionId: string,
  ): Observable<WidgetDataDTO[]> {
    let url: string;

    switch (entityType) {
      case 'portfolio':
        url = `${this.settingsState.apiPath}/widgets/${entityId}/countries?regionName=${regionId}`;
        break;

      case 'account':
        url = `${this.settingsState.apiPath}/widgets/accounts/${entityId}/countries?regionName=${regionId}`;
        break;
    }

    return this.http.get<WidgetDataDTO[]>(url);
  }

  public getDashboardCurrencies(
    range: { start: Date; end: Date },
    ccy?: string,
  ): Observable<WidgetDataDTO[]> {
    const params: Record<string, string> = {
      date: this.dateHelper.format(range.end),
    };

    if (ccy) {
      params.ccy = ccy;
    }

    return this.http.get<WidgetDataDTO[]>(
      `${this.settingsState.apiPath}/widgets/dashboard-currencies`,
      params,
    );
  }

  public getCurrencies(
    entityId: string,
    entityType: SourceEntityType,
    requestParams: {
      range: {
        start: Date;
        end: Date;
      };
      ccy?: string;
      bankAccountIds?: string[];
      productId?: string;
    },
  ): Observable<WidgetDataDTO[]> {
    let url: string;

    switch (entityType) {
      case 'portfolio':
      case 'consolidated-portfolio':
        url = `${this.settingsState.apiPath}/widgets/${entityId}/currencies`;
        break;

      case 'account':
        url = `${this.settingsState.apiPath}/widgets/accounts/${entityId}/currencies`;
        break;
    }

    const params: Record<string, string | string[]> = {
      date: this.dateHelper.format(requestParams.range.end),
    };

    if (requestParams.ccy) {
      params.ccy = requestParams.ccy;
    }

    if (requestParams.bankAccountIds?.length) {
      params.bankAccountIds = requestParams.bankAccountIds.join(',');
    }

    if (requestParams.productId) {
      params.productId = requestParams.productId;
    }

    return this.http.get<WidgetDataDTO[]>(url, { params });
  }

  public getStrategies(
    range: {
      start: Date;
      end: Date;
    },
    ccy?: string,
    entityId?: string,
    entityType?: SourceEntityType,
  ): Observable<StrategyChartPerformanceExtendedDTO[]> {
    let url: string;

    if (entityId && entityType) {
      switch (entityType) {
        case 'portfolio':
        case 'consolidated-portfolio':
          url = `${entityId}/strategies/custom-period`;
          break;

        case 'account':
          url = `accounts/${entityId}/strategies/custom-period`;
          break;
      }
    } else {
      url = 'dashboard-strategies/custom-period';
    }

    const params: Record<string, string> = {
      startDate: this.dateHelper.format(range.start),
      endDate: this.dateHelper.format(range.end),
    };

    if (ccy) {
      params.ccy = ccy;
    }

    return this.http.get<StrategyChartPerformanceExtendedDTO[]>(
      `${this.settingsState.apiPath}/widgets/${url}`,
      {
        params,
      },
    );
  }

  public getDashboardExtendedAssetTypes(
    range: {
      start: Date;
      end: Date;
    },
    ccy?: string,
  ): Observable<WidgetDataPnlPeriodsDTO[]> {
    const params: Record<string, string> = {
      startDate: this.dateHelper.format(range.start),
      endDate: this.dateHelper.format(range.end),
    };

    if (ccy) {
      params.ccy = ccy;
    }

    return this.http.get<WidgetDataPnlPeriodsDTO[]>(
      `${this.settingsState.apiPath}/widgets/dashboard-asset-types/extended/custom-period`,
      {
        params,
      },
    );
  }

  public getDashboardPerformanceByAssetTypes(
    range: {
      start: Date;
      end: Date;
    },
    ccy?: string,
  ): Observable<WidgetDataPnlPeriodsDTO[]> {
    const params: Record<string, string> = {
      startDate: this.dateHelper.format(range.start),
      endDate: this.dateHelper.format(range.end),
    };

    if (ccy) {
      params.ccy = ccy;
    }

    return this.http.get<WidgetDataPnlPeriodsDTO[]>(
      `${this.settingsState.apiPath}/widgets/dashboard-asset-types/performance/custom-period`,
      {
        params,
      },
    );
  }

  public getPositionsWithPagination(
    entityId: string,
    entityType: 'account' | 'portfolio',
    request: SearchRequestDTO,
  ): Observable<LazyData<PositionConciseDTO>> {
    let url: string;

    switch (entityType) {
      case 'portfolio':
        url = `${this.settingsState.apiPath}/widgets/portfolio/${entityId}/mobile-positions/search`;
        break;

      case 'account':
        url = `${this.settingsState.apiPath}/widgets/accounts/${entityId}/mobile-positions/search`;
        break;
    }

    return this.http
      .post<PositionConciseDTO[]>(url, request, {
        params: {
          date: this.dateHelper.format(new Date()),
          withEmptyPositions: 'false',
        },
        observe: 'response',
      })
      .pipe(
        map((res) => {
          return {
            rows: res.body,
            rowCount: Number.parseInt(res.headers.get('X-Total-Count')),
          } as LazyData<PositionConciseDTO>;
        }),
        catchError(() => of({ rows: [], rowCount: 0, errorResponse: true } as LazyData)),
      );
  }

  public getExtendedAssetTypes(
    entityId: string,
    entityType: SourceEntityType,
    requestParams: {
      range: {
        start: Date;
        end: Date;
      };
      ccy?: string;
      bankAccountIds?: string[];
    },
  ): Observable<WidgetDataPnlPeriodsDTO[]> {
    let url: string;

    switch (entityType) {
      case 'portfolio':
      case 'consolidated-portfolio':
        url = `${this.settingsState.apiPath}/widgets/${entityId}/asset-types/extended/custom-period`;
        break;

      case 'account':
        url = `${this.settingsState.apiPath}/widgets/accounts/${entityId}/asset-types/extended/custom-period`;
        break;
    }

    const params: Record<string, string | string[]> = {
      startDate: this.dateHelper.format(requestParams.range.start),
      endDate: this.dateHelper.format(requestParams.range.end),
    };

    if (requestParams.ccy) {
      params.ccy = requestParams.ccy;
    }

    if (requestParams.bankAccountIds?.length) {
      params.bankAccountIds = requestParams.bankAccountIds.join(',');
    }

    return this.http.get<WidgetDataPnlPeriodsDTO[]>(url, { params });
  }

  public getPortfolioPerformanceByAssetClass(
    entityId: string,
    entityType: SourceEntityType,
    requestParams: {
      range: {
        start: Date;
        end: Date;
      };
      ccy?: string;
      portfolioCcy?: string;
      portfolioOpenDate?: Date;
      bankAccountIds?: string[];
    },
  ): Observable<WidgetDataPnlPeriodsDTO[]> {
    let url: string;

    switch (entityType) {
      case 'portfolio':
      case 'consolidated-portfolio':
        url = `${this.settingsState.apiPath}/widgets/${entityId}/asset-types/performance/custom-period`;
        break;

      case 'account':
        url = `${this.settingsState.apiPath}/widgets/accounts/${entityId}/asset-types/performance/custom-period`;
        break;
    }

    const params: Record<string, string | string[]> = {};

    if (
      requestParams.range.start &&
      !this.dateHelper.isEqual(requestParams.range.start, requestParams.portfolioOpenDate) &&
      !this.dateHelper.isEqual(requestParams.range.start, this.dateHelper.epoch())
    ) {
      params.startDate = this.dateHelper.format(requestParams.range.start);
    }

    if (requestParams.range.end) {
      params.endDate = this.dateHelper.format(requestParams.range.end);
    }

    if (requestParams.ccy) {
      params.ccy = requestParams.ccy;
    }

    if (requestParams.bankAccountIds?.length) {
      params.bankAccountIds = requestParams.bankAccountIds.join(',');
    }

    return this.http.get<WidgetDataPnlPeriodsDTO[]>(url, { params });
  }

  public getPositionHistoryWidgetData(
    portfolioId: string,
    accountId: string,
    requestParams: {
      instrumentId: string;
      startDate: string;
      endDate: string;
      ccy?: string;
      productId?: string;
    },
  ): Observable<HistoryWidgetDTO> {
    const params: Record<string, string> = {};
    let url: string = `${this.settingsState.apiPath}/widgets`;

    if (portfolioId) {
      url += `/portfolios/${portfolioId}/position-history-widget`;
    } else {
      url += `/position-history-widget`;
    }

    if (accountId) {
      params.accountId = accountId;
    }

    if (requestParams.startDate) {
      params.startDate = requestParams.startDate;
    }

    if (requestParams.endDate) {
      params.endDate = requestParams.endDate;
    }

    if (requestParams.ccy) {
      params.currency = requestParams.ccy;
    }

    if (requestParams.instrumentId) {
      params.instrumentId = requestParams.instrumentId;
    }

    if (requestParams.productId) {
      params.productId = requestParams.productId;
    }

    return this.http.get<HistoryWidgetDTO>(url, {
      params,
    });
  }

  public getPositionFixedIncomeTableData(
    productId: string,
    portfolioId: string,
  ): Observable<DataWithError<FixedIncomeWidgetDTO>> {
    const params: Record<string, string> = {};
    let url: string;

    if (portfolioId) {
      params.portfolioId = portfolioId;
      url = `${this.settingsState.apiPath}/products/${productId}/fixed-income-data`;
    } else {
      params.productId = productId;
      url = `${this.settingsState.apiPath}/products/dashboard-fixed-income-data`;
    }

    return this.http
      .get<FixedIncomeWidgetDTO[]>(url, {
        params,
      })
      .pipe(
        map((data) => ({ data: data[0], error: null })),
        catchError((error) => of({ data: null, error })),
      );
  }

  public getStrategyProjectionHistoryData(
    strategyId: string,
    startDate: string,
    endDate: string,
  ): Observable<HistoryWidgetDTO> {
    const params: Record<string, string> = {};

    if (startDate) {
      params.startDate = startDate;
    }

    if (endDate) {
      params.endDate = endDate;
    }

    const url =
      startDate && endDate
        ? `${this.settingsState.apiPath}/strategy/${strategyId}/history-widget/custom-period`
        : `${this.settingsState.apiPath}/strategy/${strategyId}/history-widget`;

    return this.http.get<HistoryWidgetDTO>(url, {
      params,
    });
  }

  public getPortfolioHistoryWidgetData(
    portfolioId: string,
    startDate: string,
    endDate: string,
    currency: string,
    bankAccountIds: string[],
    instrumentId?: string,
  ): Observable<HistoryWidgetDTO> {
    const params: Record<string, string> = {};

    if (startDate) {
      params.startDate = startDate;
    }

    if (endDate) {
      params.endDate = endDate;
    }

    if (currency) {
      params.currency = currency;
    }

    if (bankAccountIds?.length) {
      params.bankAccountIds = bankAccountIds.join(',');
    }

    if (instrumentId) {
      params.instrumentId = instrumentId;
    }

    return this.http.get<HistoryWidgetDTO>(
      `${this.settingsState.apiPath}/widgets/${portfolioId}/history-widget`,
      {
        params,
      },
    );
  }

  public getPortfolioFixedIncomeTableData(
    portfolioId: string,
    currency: string,
    bankAccountIds: string[],
  ): Observable<DataWithError<FixedIncomeWidgetDTO>> {
    const params: Record<string, string> = {};

    if (currency) {
      params.currency = currency;
    }

    if (bankAccountIds?.length) {
      params.bankAccountIds = bankAccountIds.join(',');
    }

    return this.http
      .get<FixedIncomeWidgetDTO>(
        `${this.settingsState.apiPath}/widgets/${portfolioId}/fixed-income-widget`,
        {
          params,
        },
      )
      .pipe(
        map((data) => ({ data, error: null })),
        catchError((error) => of({ data: null, error })),
      );
  }

  public getStrategyBreakdownData(
    strategyId: string,
    currency?: string,
    bankAccountIds?: string[],
  ): Observable<DataWithError<WidgetDataDTO[]>> {
    const params: Record<string, string> = {};

    if (currency) {
      params.currency = currency;
    }

    if (bankAccountIds?.length) {
      params.bankAccountIds = bankAccountIds.join(',');
    }

    return this.http
      .get<WidgetDataDTO[]>(`${this.settingsState.apiPath}/products/${strategyId}/all-widgets`, {
        params,
      })
      .pipe(
        map((data) => ({ data, error: null })),
        catchError((error) => of({ data: null, error })),
      );
  }

  public getPortfolioCorrelationData(
    portfolioId: string,
    benchmarks: string[],
    bankAccountIds: string[],
    currency: string,
    startDate: string,
    endDate: string,
  ): Observable<DataWithError<MarketCorrelationDTO[]>> {
    const params: Record<string, string> = {};

    if (benchmarks?.length > 0) {
      params.benchmarks = benchmarks.join(',');
    }

    if (bankAccountIds) {
      params.bankAccountIds = bankAccountIds.join(',');
    }

    if (currency) {
      params.currency = currency;
    }

    if (startDate) {
      params.startDate = startDate;
    }

    if (endDate) {
      params.endDate = endDate;
    }

    return this.http
      .get<MarketCorrelationDTO[]>(
        `${this.settingsState.apiPath}/widgets/${portfolioId}/correlation`,
        {
          params,
        },
      )
      .pipe(
        map((data) => ({ data, error: null })),
        catchError((error) => of({ data: null, error })),
      );
  }

  public getMarketsPerformance(
    marketId: string,
    startDate: string = null,
    endDate: string = null,
    accountId: string = null,
    instrumentId: string = null,
    productId: string = null,
    includeProducts: boolean = true,
    parentAccount: boolean = false,
  ): Observable<{ date?: Date; val: number }[]> {
    const params: { [param: string]: string | string[] | boolean } = {};

    params.marketId = marketId;
    params.includeProducts = includeProducts;
    params.parentAccount = parentAccount;

    if (startDate) {
      params.startDate = startDate;
    }

    if (endDate) {
      params.endDate = endDate;
    }

    if (accountId) {
      params.accountId = accountId;
    }

    if (instrumentId) {
      params.instrumentId = instrumentId;
    }

    if (productId) {
      params.productId = productId;
    }

    return this.http
      .get(`${this.settingsState.apiPath}/widgets/dashboard-market-performance`, { params })
      .pipe(
        map((performance: SimpleDateValueDTO[]) =>
          performance?.length
            ? performance.map((item) => {
                const date = this.dateHelper.parse(item.date);
                date.setHours(0);
                date.setMinutes(0);

                return { val: item.val, date };
              })
            : null,
        ),
      );
  }

  public getMarketsPerformanceByPortfolio(
    marketId: string,
    portfolioId: string,
    startDate: string = null,
    endDate: string = null,
    productId: string = null,
    instrumentId: string = null,
    includeProducts: boolean = true,
  ): Observable<WidgetDateValue[]> {
    const params: { [param: string]: string | string[] | boolean } = {};

    params.marketId = marketId;
    params.includeProducts = includeProducts;

    if (startDate) {
      params.startDate = startDate;
    }

    if (endDate) {
      params.endDate = endDate;
    }

    if (instrumentId) {
      params.instrumentId = instrumentId;
    }

    if (productId) {
      params.productId = productId;
    }

    return this.http
      .get(`${this.settingsState.apiPath}/widgets/portfolios/${portfolioId}/market-performance`, {
        params,
      })
      .pipe(
        map((performance: SimpleDateValueDTO[]) =>
          performance?.length
            ? performance.map((item) => {
                const date = this.dateHelper.parse(item.date);
                date.setHours(0);
                date.setMinutes(0);

                return { val: item.val, date };
              })
            : null,
        ),
      );
  }

  public getMarketsPerformanceByAccount(
    marketId: string,
    accountId: string,
    startDate: string = null,
    endDate: string = null,
  ): Observable<WidgetDateValue[]> {
    const params: { [param: string]: string | string[] | boolean } = {};

    params.marketId = marketId;

    if (startDate) {
      params.startDate = startDate;
    }

    if (endDate) {
      params.endDate = endDate;
    }

    return this.http
      .get(`${this.settingsState.apiPath}/widgets/accounts/${accountId}/market-performance`, {
        params,
      })
      .pipe(
        map((performance: SimpleDateValueDTO[]) =>
          performance?.length
            ? performance.map((item) => {
                const date = this.dateHelper.parse(item.date);
                date.setHours(0);
                date.setMinutes(0);

                return { val: item.val, date };
              })
            : null,
        ),
      );
  }

  public getMarketsPerformanceByBankAccounts(
    marketId: string,
    bankAccountIds: string[],
    startDate: string = null,
    endDate: string = null,
    instrumentId: string = null,
    productId: string = null,
    includeProducts: boolean = true,
  ): Observable<WidgetDateValue[]> {
    const params: { [param: string]: string | string[] | boolean } = {};

    params.marketId = marketId;
    params.bankAccountIds = bankAccountIds;
    params.includeProducts = includeProducts;

    if (startDate) {
      params.startDate = startDate;
    }

    if (endDate) {
      params.endDate = endDate;
    }

    if (instrumentId) {
      params.instrumentId = instrumentId;
    }

    if (productId) {
      params.productId = productId;
    }

    return this.http
      .get(`${this.settingsState.apiPath}/widgets/bank-accounts/market-performance`, { params })
      .pipe(
        map((performance: SimpleDateValueDTO[]) =>
          performance?.length
            ? performance.map((item) => {
                const date = this.dateHelper.parse(item.date);
                date.setHours(0);
                date.setMinutes(0);

                return { val: item.val, date };
              })
            : null,
        ),
      );
  }

  public getAdvancedAnalyticsChart(
    entityId: string,
    startDate: string,
    endDate: string,
    tab: string,
    bankAccountIds: string[],
    currency: string,
  ): Observable<AdvancedAnalyticsDataDTO> {
    const params: Record<string, string> = {};

    if (startDate) {
      params.startDate = startDate;
    }

    if (endDate) {
      params.date = endDate;
    }

    if (bankAccountIds?.length) {
      params.bankAccountIds = bankAccountIds.join(',');
    }

    if (tab) {
      params.tab = tab;
    }

    if (currency) {
      params.ccy = currency;
    }

    return this.http.get<AdvancedAnalyticsDataDTO>(
      `${this.settingsState.apiPath}/portfolios/${entityId}/risk-analysis/graph`,
      { params },
    );
  }

  public getAdvancedAnalyticsWidgets(
    entityId: string,
    startDate: string,
    endDate: string,
    tab: string,
    bankAccountIds: string[],
    currency: string,
  ): Observable<DataWithError<AdvancedAnalyticsDataDTO>> {
    const params: Record<string, string> = {};

    if (startDate) {
      params.startDate = startDate;
    }

    if (endDate) {
      params.date = endDate;
    }

    if (bankAccountIds?.length) {
      params.bankAccountIds = bankAccountIds.join(',');
    }

    if (tab) {
      params.tab = tab;
    }

    if (currency) {
      params.ccy = currency;
    }

    return this.http
      .get<AdvancedAnalyticsDataDTO>(
        `${this.settingsState.apiPath}/portfolios/${entityId}/risk-analysis/widgets`,
        { params },
      )
      .pipe(
        map((data) => ({ data, error: null })),
        catchError((error) => of({ data: null, error })),
      );
  }
}
